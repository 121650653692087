<template>
  <!-- 访客记录 -->
  <div class="record">
    <div class="recordSta">
      <!-- <div class="recordStaCon">
        <div class="recordStaName">通过人数：</div>
        <div class="recordStaNum">
          {{ pagingData.stat.totalVisitedPersons || 0 }}
        </div>
      </div> -->
      <div class="recordStaCon">
        <div class="recordStaName">扫码次数：</div>
        <div class="recordStaNum">
          {{ pagingData.healthColorStat.allColor || 0 }}
        </div>
      </div>
      <div class="recordStaCon">
        <div class="recordStaName">绿码：</div>
        <div class="recordStaNum recordStaNum1">
          {{ pagingData.healthColorStat.greenColor || 0 }}
        </div>
      </div>
      <div class="recordStaCon">
        <div class="recordStaName">黄码：</div>
        <div class="recordStaNum recordStaNum2">
          {{ pagingData.healthColorStat.yellowColor || 0 }}
        </div>
      </div>
      <div class="recordStaCon">
        <div class="recordStaName">红码：</div>
        <div class="recordStaNum recordStaNum3">
          {{ pagingData.healthColorStat.redColor || 0 }}
        </div>
      </div>
    </div>
    <div class="recordCon">
      <div class="recordSearch">
        <div class="titleSearch">
          <div class="titleS">
            <div v-if="isDis !== 2" class="titleSear">
              <el-select
                @change="companyBlur"
                @blur="companyBlur"
                @keyup.enter.native="comSearchFun"
                :filter-method="myAddcomFocus"
                ref="select"
                v-model="company"
                placeholder="所属场所"
                filterable
                clearable
                :loading="companyloading"
              >
                <el-option
                  v-for="(item, index) in companys"
                  :key="index"
                  :label="item.placeName"
                  :value="item.placeName"
                >
                </el-option>
              </el-select>
              <i class="el-icon-search iconSearch" @click="comSearchFun"></i>
            </div>
            <el-select
              class="gateSty"
              v-model="gate"
              placeholder="通过点位"
              clearable
            >
              <el-option
                v-for="(item, index) in gateData"
                :key="index"
                :label="item.doorName"
                :value="item.doorName"
              >
              </el-option>
            </el-select>
            <el-select
              class="codeColor"
              v-model="codeColor"
              placeholder="健康码颜色"
              clearable
            >
              <el-option
                v-for="(item, index) in codeColors"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <div class="timeSty">
              <el-date-picker
                @change="timeChange"
                v-model="time"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="titleS">
            <el-input
              @keyup.enter.native="searchClick"
              v-model="input"
              placeholder="脱敏姓名/身份证/手机号"
              clearable
            ></el-input>
            <el-button
              @click="searchClick"
              class="titleButton"
              type="primary"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button
              @click="exportClick"
              class="titleButton"
              icon="el-icon-download"
              :loading="exportLoad"
              >导出数据
              <a title="data"></a>
            </el-button>
          </div>
        </div>
      </div>
      <div class="recordTableC">
        <div class="recordTable">
          <el-table
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            height="100%"
          >
            <el-table-column prop="placeName" label="所属场所" min-width="240">
            </el-table-column>
            <el-table-column prop="visitTime" label="到访时间" min-width="160">
            </el-table-column>
            <el-table-column prop="doorName" label="通过点位" min-width="90">
            </el-table-column>
            <el-table-column prop="visitorName" label="访客姓名" min-width="90">
            </el-table-column>
            <el-table-column
              prop="visitorCardNo"
              label="身份证号码"
              min-width="140"
            >
            </el-table-column>
            <el-table-column
              prop="visitorTemperature"
              label="体温"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="healthColorTip"
              label="健康码颜色"
              min-width="100"
            >
            </el-table-column>
            <el-table-column
              prop="isFollowPersonA"
              label="本地关注人员"
              align="center"
              min-width="110"
            >
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button
                  @click="handleClick(scope.row, scope.$index)"
                  type="text"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          :current-page="currentPage"
          :page-size="pagingData.page.pageSize || 0"
          @current-change="handleCurrentChange"
          background
          layout="total, prev, pager, next"
          :total="pagingData.page.totalRecords || 0"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗-详情 -->
    <detail
      v-if="detailsShow"
      @details-fun="detailsFun"
      :tableData="tableData[recordIndex]"
    ></detail>
  </div>
</template>
<script>
import dayjs from "dayjs";
import detail from "../../components/detail";

export default {
  name: "record",
  components: { detail },
  data() {
    return {
      loading: false,
      exportLoad: false,
      // 加载所属场所
      companyloading: false,
      time: [],
      // 是否显示-弹窗-详情
      detailsShow: false,
      // 所属场所
      company: "",
      companys: [],
      // 通过点位
      gate: null,
      // 获取到的 通过点位列表
      gateData: [],
      // 健康码颜色
      codeColor: "",
      codeColors: ["绿码", "黄码", "红码"],
      input: "",
      // 表格
      tableData: [],
      // 访客记录-拉取到的数据
      pagingData: {
        healthColorStat: {},
        page: {},
        stat: {},
      },
      // 分页-总条数
      totalNum: null,
      // 当前页数
      currentPage: 1,
      //列表-开始位置
      start: 1,
      // 下拉选择-健康码颜色
      myColor: null,
      // 下拉选择-所属场所
      placeId: "",
      // 开始时间
      startTime: "",
      // 结束时间
      endTime: "",
      // 详情-被选中下标
      recordIndex: 1,
      // 权限
      isDis: null,
    };
  },
  created() {
    // 不同权限 被访问到的页面
    let userInfo = sessionStorage.getItem("userInfo");
    this.isDis = Number(userInfo);

    // 通过点位，用于下拉框
    // 如果是场所权限
    if (this.isDis === 2) {
      this.placeId = sessionStorage.getItem("placeId");
      this.getGate(this.placeId);
    }
    // 时间
    this.myTime();
    // 访客记录
    this.getPage();
  },
  methods: {
    // 所属场所-点击搜索图标
    comSearchFun() {
      this.companys = [];
      // 触发select获取焦点时
      this.$refs.select.focus();
      this.companyloading = true;
      this.getPlace();
    },

    // 所属场所-当 input 发生改变时
    myAddcomFocus(val) {
      this.company = val;
    },
    // 时间
    myTime() {
      const end = new Date();
      const start = new Date();
      // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      // 开始时间
      this.startTime = dayjs(start)
        .startOf("day")
        .format("YYYY/MM/DDTHH:mm:ss");
      // 结束时间
      this.endTime = dayjs(end).endOf("day").format("YYYY/MM/DDTHH:mm:ss");
      this.time = [this.startTime, this.endTime];
    },
    // 时间-用户确认选定的值时触发
    timeChange() {
      if (this.time !== null) {
        // 开始时间
        let startTime = dayjs(this.time[0]).format("YYYY/MM/DD HH:mm:ss");
        // 结束时间
        let endTime = dayjs(this.time[1]).format("YYYY/MM/DD HH:mm:ss");
        this.time = [startTime, endTime];
      }
    },
    // 点击搜索
    searchClick() {
      this.currentPage = 1;
      // 搜索并拉取列表
      this.getPage();
    },
    // 点击导出
    exportClick() {
      this.getExport();
    },
    //  @param {String} href
    //  @param {String} filename
    imatateDownloadByA(href, filename) {
      const a = document.createElement("a");
      a.download = filename;
      a.style.display = "none";
      a.href = href;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(href);
    },
    // 导出数据
    getExport() {
      this.exportLoad = true;
      this.color();
      this.$instance
        .get("/v1/visit-log/download", {
          params: {
            pageSize: 10000,
            // 点位id
            doorId: this.gate ? this.doorId : "",
            // 健康码颜色
            healthColor: this.codeColor ? this.myColor : null,
            // 场所id
            placeId: this.company ? this.placeId : "",
            // 开始时间
            startTime: this.time[0],
            // 结束时间
            endTime: this.time[1],
            // 搜索关键字
            key: this.input,
          },
          responseType: "arraybuffer",
        })
        .then((res) => {
          // console.log('res1: ', res)
          // const blob = new Blob([res.data])
          const blob = new Blob([res.data], {
            type:
              // 'application/vnd.ms-excel;charset=utf-8',
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          // IE
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "data");
          } else {
            this.imatateDownloadByA(window.URL.createObjectURL(blob), "data");
          }
          this.exportLoad = false;
        })
        .catch((error) => {
          console.log("error: ", error);
          this.exportLoad = false;
        });
    },
    // 弹窗-详情-点击关闭
    detailsFun() {
      this.detailsShow = false;
    },
    // 点击 详情
    handleClick(row, index) {
      this.recordIndex = index;
      this.detailsShow = true;
    },
    // 点击分页-当前页
    handleCurrentChange(val) {
      this.currentPage = val;
      // 搜索并拉取列表
      this.getPage();
    },
    // 传值 转化
    color() {
      // 健康码颜色赋值
      if (this.codeColor === "绿码") {
        this.myColor = 1;
      } else if (this.codeColor === "黄码") {
        this.myColor = 11;
      } else if (this.codeColor === "红码") {
        this.myColor = 31;
      }
      // 如果没有时间选择 默认最近一个月
      if (!this.time) {
        this.time = [this.startTime, this.endTime];
      }
      this.time[0] = this.time[0].replace(/\\|\//g, "-");
      this.time[1] = this.time[1].replace(/\\|\//g, "-");
      // 点位ID赋值
      this.gateData.map((val) => {
        if (this.gate && this.gate === val.doorName) {
          this.doorId = val.id;
        }
      });
    },
    // 访客记录
    getPage() {
      this.loading = true;
      this.color();

      this.$instance
        .get("/v1/visit-log/list-page", {
          params: {
            pageNum: this.currentPage - this.start,
            pageSize: 10,
            // 点位id
            doorId: this.gate ? this.doorId : "",
            // 健康码颜色
            healthColor: this.codeColor ? this.myColor : "",
            // 场所id
            placeId: this.company ? this.placeId : "",
            // 开始时间
            startTime: this.time[0],
            // 结束时间
            endTime: this.time[1],
            // 搜索关键字
            key: this.input,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.loading = false;
            this.pagingData = res.data.data;
            this.tableData = this.pagingData.page.elements;
            this.tableData.map((val) => {
              val.visitorCardNo = val.visitorCardNo.replace(
                /(\d{6})\d{10}(\d{2})/,
                "$1**********$2"
              );
              val.visitorPhoneNo = val.visitorPhoneNo.replace(
                /(\d{3})\d{4}(\d{4})/,
                "$1****$2"
              );
              if (val.isFollowPerson === "1") {
                val.isFollowPersonA = "是";
              } else {
                val.isFollowPersonA = "否";
              }
            });
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 场所列表，用于下拉框
    getPlace() {
      this.$instance
        .get("/v1/place/search", {
          params: { placeName: this.company },
        })
        .then((res) => {
          if (res.data.status === 200) {
            this.companyloading = false;
            this.companys = res.data.data;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
    // 所属场所 失去焦点时
    companyBlur() {
      // 场所ID赋值
      if (this.company) {
        this.companys.map((val) => {
          if (this.company === val.placeName) {
            this.placeId = val.id;
            this.getGate(this.placeId);
          }
        });
      } else {
        this.gateData = [];
      }
    },
    // 通过点位，用于下拉框
    getGate(placeId) {
      this.$instance
        .get("/v1/door/list", {
          params: {
            placeId,
          },
        })
        .then((res) => {
          // console.log('res: ', res.data.data)
          if (res.data.status === 200) {
            let gateData = res.data.data;
            this.gateData = gateData;
          }
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.record {
  width: calc(100% - 40px);
  margin: 20px;
  .recordSta {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    margin-bottom: 20px;
    padding: 0 120px;
    box-sizing: border-box;
    .recordStaCon {
      display: flex;
      align-items: center;
      .recordStaNum {
        color: #333;
        font-size: 20px;
        font-weight: bold;
      }
      .recordStaNum1 {
        color: rgb(0, 128, 0);
      }
      .recordStaNum2 {
        color: rgb(240, 183, 37);
      }
      .recordStaNum3 {
        color: rgb(222, 39, 39);
      }
    }
  }
  & ::v-deep .recordCon {
    width: 100%;
    height: calc(100% - 60px);
    background-color: #fff;
    // 搜索
    .recordSearch {
      width: 100%;
      padding: 16px 20px 6px;
      box-sizing: border-box;
      border-bottom: 1px #f0f0f0 solid;
      .titleSearch {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        .titleS {
          display: flex;
          margin-bottom: 14px;
          margin-right: 10px;
          .titleSear {
            position: relative;
            .el-select {
              .el-input__inner {
                padding-right: 46px;
              }
              .el-input__suffix {
                margin-right: 18px;
                .el-icon-arrow-up {
                  display: none;
                }
              }
            }
            .iconSearch {
              width: 24px;
              position: absolute;
              top: 9px;
              right: 14px;
              color: rgb(182, 182, 182);
              font-size: 14px;
              background-color: #fff;
              text-align: center;
              z-index: 2;
              cursor: pointer;
            }
          }
          .timeSty {
            margin-left: 10px;
            .el-date-editor {
              width: 360px;
            }
            .el-input__icon,
            .el-range-separator {
              height: unset;
            }
          }
          .el-input__inner {
            height: 32px;
            line-height: 32px;
          }
          .el-button--primary {
            height: 32px;
            border: unset;
          }
          .titleButton {
            line-height: 30px;
            padding: 0 12px;
          }
          .el-select > .el-input,
          .el-input {
            margin-right: 10px;
          }
          .codeColor > .el-input {
            margin-right: unset;
          }
          .el-select > .el-input {
            width: 180px;
          }
          .gateSty > .el-input {
            width: 110px;
          }
          .codeColor > .el-input {
            width: 120px;
          }
          .el-input {
            width: 200px;
            .el-input__suffix {
              i {
                line-height: 32px;
              }
            }
          }
        }
      }
    }
    .recordTableC {
      width: calc(100% - 40px);
      height: calc(100% - 176px);
      margin: 20px;
      // 表格
      .recordTable {
        height: 100%;
        border: 1px #f0f0f0 solid;
        overflow: auto;
        .el-table td,
        .el-table th {
          padding: 4px 0;
          .cell {
            display: flex;
          }
        }
      }
    }
  }
}
</style>
