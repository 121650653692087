<template>
    <!-- 访客记录-详情 -->
    <div class="detail">
        <div class="mask"></div>
        <div class="detailsCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="detailsFun"></i>
            </div>
            <div class="cardCon">
                <div>
                    <div class="cardConText" v-for="(itme, index) in details" :key="index">
                        <div class="conTextTitle">{{ itme.title }}：</div>
                        <div class="conTextName">{{ itme.text }}</div>
                    </div>
                </div>
            </div>
            <div class="button">
                <el-button type="primary" @click="detailsFun">关 闭</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'detail',
        props: {
            // 到访详情数据
            tableData: {
                type: Object,
                default() {
                    return {}
                },
            },
        },
        data() {
            return {
                catdTitle: '到访详情',
                // 详情内容
                details: [
                    {
                        title: '所属场所',
                        text: '0',
                    },
                    {
                        title: '场所ID',
                        text: '1',
                    },
                    {
                        title: '通过点位',
                        text: '2',
                    },
                    {
                        title: '点位ID',
                        text: '3',
                    },
                    {
                        title: '到访时间',
                        text: '4',
                    },
                    {
                        title: '访客姓名',
                        text: '5',
                    },
                    {
                        title: '健康码颜色',
                        text: '6',
                    },
                    {
                        title: '风险原因',
                        text: '7',
                    },
                    {
                        title: '体温',
                        text: '8',
                    },
                    // {
                    //     title: '登记方式',
                    //     text: '9',
                    // },
                    {
                        title: '身份证号码',
                        text: '9',
                    },
                    {
                        title: '手机号码',
                        text: '10',
                    },
                    {
                        title: '是否为关注人员',
                        text: '11',
                    },
                ],
                // 登记方式
                logType: ['扫健康码', '扫身份证', '手工登记'],
            }
        },
        created() {
            this.details[0].text = this.tableData.placeName
            this.details[1].text = this.tableData.placeId
            this.details[2].text = this.tableData.doorName
            this.details[3].text = this.tableData.doorId
            this.details[4].text = this.tableData.visitTime
            this.details[5].text = this.tableData.visitorName
            this.details[6].text = this.tableData.healthColorTip
            this.details[7].text = this.tableData.healthColorDesc
            this.details[8].text = this.tableData.visitorTemperature
            // this.details[9].text = this.logType[this.tableData.logType - 1]
            this.details[9].text = this.tableData.visitorCardNo
            this.details[10].text = this.tableData.visitorPhoneNo
            this.details[11].text =
                this.tableData.isFollowPerson === '1' ? '是' : '否'
            if (this.tableData.isFollowPerson === '1') {
                this.details[12] = {
                    title: '关注人员备注',
                    text:
                        this.tableData.followName + '-' + this.tableData.followTips,
                }
            }
        },
        methods: {
            // 点击关闭
            detailsFun() {
                this.$emit('details-fun')
            },
        },
    }
</script>
<style lang="scss" scoped>
    .detail {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        .mask {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.4);
        }
        // 弹窗卡片
        .detailsCard {
            width: 500px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            border-radius: 6px;
            // 头部
            .cardHeader {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                padding: 0 40px;
                box-sizing: border-box;
                border-radius: 6px 6px 0 0;
                // 头部-icon
                .el-icon-close {
                    font-size: 24px;
                }
            }
            .cardCon {
                display: flex;
                padding: 20px 40px 10px;
                border-top: 1px #f0f0f0 solid;
                border-bottom: 1px #f0f0f0 solid;
                .cardConImg {
                    width: 80px;
                    height: 100px;
                    background-color: #f0f0f0;
                    object-fit: cover;
                    margin: 20px 40px 0 0;
                }
                .cardConText {
                    display: flex;
                    margin-bottom: 12px;
                    .conTextTitle {
                        width: 118px;
                        flex: none;
                        margin-right: 6px;
                        text-align: right;
                    }
                }
            }
        }
        .button {
            padding: 20px 40px;
            display: flex;
            justify-content: flex-end;
            .el-button {
                line-height: 30px;
                padding: 0 20px;
            }
        }
    }
</style>
